import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo8.png";
import noticias16 from "../../../../static/img/blog/noticias-16-.png";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='2021: Un vistazo a la economía mundial '>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias16}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                12 Ene. 2021
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Ricardo Ávila
                </span>
                                <span>
                    Análisis del mercado
                </span>
                            </div>
                        </div>

                        <h2>2021: Un vistazo a la economía mundial  </h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                        <p>El 2020 trajo consigo una serie de acontecimientos para los cuales nadie se encontraba preparado.
                        El brote del virus SARS-CoV-2 (a finales de 2019) en la ciudad de Wuhan, China, junto con su posterior
                        propagación a nivel global y las modificaciones al estilo de vida relacionadas con su prevención
                        (medidas de confinamiento y cierre de fronteras), las oleadas de cambios sociales en diferentes
                        partes del mundo y el proceso electoral federal en EE. UU., constituyen, entre otros hechos, los
                        principales sucesos de un año marcado por la acentuación de una aguda recesión económica. </p>

                        <p>Aquí cabe recordar que, luego del súbito y pronunciado declive a principios de 2020, la economía
                        mundial presentó signos de recuperación en mayo, generando que se creen expectativas de un
                        escenario de rápido crecimiento para 2021. En este respecto, en medio de la crisis sanitaria y
                        económica propiciada por la pandemia de COVID-19, diversos especialistas económicos han
                        preparado sus pronósticos sobre el PIB mundial para el ejercicio 2021.</p>

                        <p><b>Morgan Stanley</b></p>

                        <p>En su más reciente proyección, el equipo económico de investigación afirma que la recuperación en
                        forma de V, que pronosticaron a mediados del 2020, está entrando en una nueva fase autosostenible
                        en la que parece transitar hacia un crecimiento de 6.4% para 2021. Entre los factores que la firma
                        asegura caracterizaran la mejoría se encuentran: un crecimiento mundial sincronizado, el repunte de
                        los mercados emergentes y una posible alza en la inflación. </p>

                        <p><b>Fitch Ratings</b></p>

                        <p>De acuerdo con la agencia calificadora, el camino hacia la recuperación global está resultando más
                        accidentado de lo esperado. Sin embargo, esperan que esta acelere su ritmo y sea más tangible a
                        partir del 2T21 (en línea con la implementación de las vacunas contra COVID-19). En este sentido, su
                        pronóstico del PIB mundial para 2021 mejoró a 5.3%, esperando un mayor dinamismo en todas las
                        economías del mundo. </p>

                        <p><b>Bank of America Global Research</b></p>

                        <p>Acorde a la investigación del comité de Inversión del banco, este nuevo año tendrá uno de los
                        comienzos más complicados de los últimos tiempos, ya que muchos países centrarán su atención en
                        la lucha contra los rebrotes de COVID-19. No obstante, dicho comité espera que el efecto combinado
                        de un posible estímulo fiscal en EE. UU. y una amplia distribución de vacunas alrededor del mundo
                        durante el primer semestre del 2021, contribuyan a una reapertura económica más exhaustiva y ágil,
                        por lo que proyecta que el PIB mundial crecerá 5.4% en el 2021, en un entorno donde la inflación y
                        diversas tasas de interés continúen en niveles bajos. </p>

                        <p><b>Goldman Sachs </b></p>

                        <p>La segunda ola de infecciones, que actualmente se encuentra afectando a EE. UU. y Europa, se
                        posiciona como el principal factor de la baja en la estimación que el grupo había realizado
                        anteriormente, por lo cual, en su más reciente proyección señalan que el PIB mundial se expandirá
                        un 6.0%. En este respecto, consideran que, a pesar de prever un significativo repunte económico, los
                        países más desarrollados continuarán ofreciendo subsidios salariales y mayores prestaciones de desempleo, a manera
                        de que las condiciones del mercado laboral se normalicen gradualmente a lo largo del año.</p>

                        <p><b>Fondo Monetario Internacional (FMI) </b></p>

                        <p>En la última actualización de sus “Perspectivas de la Economía Mundial”, en octubre de 2020, el FMI
                        detalló que, si bien la reapertura que experimentaron las economías avanzadas durante el 2T20 y el
                        aumento en la movilidad social incentivaron una mejora en su proyección sobre la contracción del
                        2020, su pronóstico para el PIB mundial del 2021 sufrió una baja de 2 p.p., al predecir un crecimiento
                        de 5.2%. La corrección corresponde a la incertidumbre que el fondo vislumbra persistirá durante todo
                        el año con relación al tema de las vacunas, su distribución mundial y las cuarentenas parciales que
                        diversos países seguirán implementando.</p>

                        <p>Referencias Bibliográficas
                          <ul>
                            <li>Morgan Stanley. 2021 Global Economic Outlook: The Next Phase of the V. Diciembre 01, 2020. <a href="https://www.morganstanley.com/ideas/global-economic-outlook-2021?cid=SM_CORP_SM_CORP_TWITTER_MorganStanley_20201206&linkId=106300957" target="_blank">Morgan Stanley</a>.</li>
                            <li>Fitch Ratings. World GDP Recovery to Strengthen from Mid-2021 on Vaccine Rollout. Diciembre 07, 2020. <a href="https://www.fitchratings.com/research/sovereigns/world-gdp-recovery-to-strengthen-from-mid-2021-on-vaccine-rollout-07-12-2020" target="_blank">Fitch Ratings</a>.</li>
                            <li>Winck, B. Modest market returns in 2021 risk disappointing today's overexcited investors, Bank of America says. Diciembre 12, 2020.  <a href="https://markets.businessinsider.com/news/stocks/stock-market-outlook-modest-returns-economic-growth-overexcited-investors-bofa-2020-12-1029887195" target="_blank">Markets Insider</a>.</li>
                            <li>Hatzius, J., Struyven, D., Bhushan, Sid., Milo, D. Global Economics Analyst: V(accine)-Shaped Recovery. Noviembre 7, 2020. <a href="https://www.goldmansachs.com/insights/pages/gs-research/macro-outlook-2021/report.pdf" target="_blank">Goldman Sachs Global Investment Research</a>.</li>
                            <li>Fondo Monetario Internacional. Perspectivas de la Economía Mundial. Octubre 11, 2020. <a href="https://www.imf.org/es/Publications/WEO/Issues/2020/09/30/world-economic-outlook-october-2020" target="_blank">FMI</a>.</li>
                          </ul>
                        </p>

                </div>
              </div>
        </Single>
    )
}

export default Detail
